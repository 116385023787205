import * as yup from "yup";

export const yupSchema = yup.object().shape({
  codigo: yup.string().required("Esse campo é obrigatório"),
  descricao: yup.string().required("Esse campo é obrigatório"),
});

export const initialValues = {
  codigo: "",
  descricao: "",
};
