import HttpService from "./http.service";

class CnpjService {
  get = async (cnpj) => {
    const cnpjEndpoint = `cnpj`;
    return await HttpService.get(`${cnpjEndpoint}/${cnpj}`);
  };
}

export default new CnpjService();
