import HttpService from "./http.service";

class ReceivedService {
  list = async (sortStr = null, filterStr = null) => {
    const receivedsEndpoint = `/receiveds?limit=-1${sortStr ? sortStr : ""}${filterStr ? filterStr : ""}`;
    return await HttpService.get(receivedsEndpoint);
  };

  listFromPayableProduct = async (page = 0, limit = 25, sortStr = null, filterStr = null, numPedido, codigoInterno) => {
    const receivedsEndpoint = `/receiveds/payable-product/${numPedido}/${codigoInterno}/?page=${page}&limit=${limit}${sortStr ? sortStr : ""}${filterStr ? filterStr : ""}`;
    return await HttpService.get(receivedsEndpoint);
  };

  listFromPayable = async (page = 0, limit = 25, sortStr = null, filterStr = null, numPedido) => {
    const receivedsEndpoint = `/receiveds/payable/${numPedido}/?page=${page}&limit=${limit}${sortStr ? sortStr : ""}${filterStr ? filterStr : ""}`;
    return await HttpService.get(receivedsEndpoint);
  };

  paginate = async (page = 0, limit = 25, sortStr = null, filterStr = null) => {
    const receivedsEndpoint = `/receiveds?page=${page}&limit=${limit}${sortStr ? sortStr : ""}${filterStr ? filterStr : ""}`;
    return await HttpService.get(receivedsEndpoint);
  };

  get = async (id) => {
    const receivedsEndpoint = "/receiveds";
    return await HttpService.get(`${receivedsEndpoint}/${id}`);
  };

  create = async (data) => {
    const receivedsEndpoint = "/receiveds";
    return await HttpService.post(receivedsEndpoint, data);
  };

  update = async (id, data) => {
    const receivedsEndpoint = "/receiveds";
    return await HttpService.put(`${receivedsEndpoint}/${id}`, data);
  };

  delete = async (id) => {
    const receivedsEndpoint = "/receiveds";
    return await HttpService.delete(`${receivedsEndpoint}/${id}`);
  };
}

export default new ReceivedService();
