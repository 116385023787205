// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Data
import { useEffect, useState } from "react";

import { Box, Button, CardContent, CardHeader, CircularProgress, Stack, TextField, Tooltip } from "@mui/material";
import WarehouseService from "services/warehouses";
import Service from "services/stock";
import ServiceDestaque from "services/stock-dashboard";

import ConfigurarDashboard from "./components/configDashboard";

export default function AlmoxarifadoEstoque() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataDestaque, setDataDestaque] = useState([]);
  const [configDashboard, setConfigDashboard] = useState(false);

  const [warehouseId, setWarehouseId] = useState({
    id: "",
    nome: "",
  });
  const [warehouses, setWarehouses] = useState([]);

  const fetchData = async () => {
    setLoading(true);

    const { data } = await Service.listByWarehouse(warehouseId?.id);
    const dataDestaque = await ServiceDestaque.listByWarehouse(warehouseId?.id);
    setDataDestaque(dataDestaque);
    setData(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [warehouseId]);

  useEffect(() => {
    const fetchWarehouse = async () => {
      const warehouses = await WarehouseService.list();
      setWarehouses(warehouses);
      warehouses &&
        setWarehouseId({
          id: warehouses?.data[0]?._id,
          nome: warehouses?.data[0]?.descricao,
        });
    };

    fetchWarehouse();
  }, []);

  const calculateTotal = () => {
    let total = 0;
    data.map((item) => {
      total += item.valorRecebido;
    });
    return total;
  };

  const calculateProgress = (idProduto, minQuantity, maxQuantity) => {
    const item = data.find((item) => item.produto === idProduto);
    const qtdRecebida = item?.qtdRecebida || 0;
    const result = (qtdRecebida / (minQuantity + maxQuantity)) * 50;
    return result;
  };

  return (
    <DashboardLayout>
      {configDashboard && <ConfigurarDashboard setConfigDashboard={setConfigDashboard} warehouse={warehouseId} fetchDataParent={fetchData} />}
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" sx={{ display: "flex", justifyContent: "space-between" }}>
                <MDTypography variant="h6" color="white" mt={1}>
                  Almoxarifado - Estoque
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "top",
                    justifyContent: "space-between",
                    margin: "1rem",
                  }}
                >
                  <TextField
                    select
                    label="Selecione o Depósito"
                    onChange={(e) =>
                      setWarehouseId({
                        id: e.target.value,
                        nome: e.target.options[e.target.selectedIndex].text,
                      })
                    }
                    value={warehouseId.id}
                    SelectProps={{
                      native: true,
                    }}
                    sx={{
                      width: "400px",
                    }}
                  >
                    {warehouses?.data?.length > 0 &&
                      warehouses?.data?.map((option) => (
                        <option key={option._id} value={option._id}>
                          {option.descricao}
                        </option>
                      ))}
                  </TextField>
                  <Button
                    variant="contained"
                    sx={{
                      color: "#fff",
                    }}
                    onClick={() => setConfigDashboard(true)}
                  >
                    Configurar Dashboard
                  </Button>
                </Stack>
                <Stack sx={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                  <Stack sx={{ display: "flex", flexDirection: "column" }}>
                    <Card
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "8px",
                        margin: "1rem",
                        width: "auto",
                        height: "200px",
                        backgroundColor: "#d7e0e1",
                      }}
                    >
                      <CardHeader title="VALOR TOTAL EM ESTOQUE" />
                      <CardContent
                        sx={{
                          color: "green",
                          fontWeight: "bold",
                          fontSize: "3.5rem",
                          textAlign: "right",
                          ":before": {
                            content: '"R$"',
                            fontSize: "1.5rem",
                            padding: "0.5rem",
                          },
                        }}
                      >
                        {calculateTotal()?.toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </CardContent>
                    </Card>
                    <Stack sx={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                      {dataDestaque?.map((item) => (
                        <Card
                          key={item._id}
                          sx={{
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: "8px",
                            margin: "1rem",
                            width: "auto",
                            height: "150px",
                            paddingInline: "1rem",
                            backgroundColor: "#d7e0e1",
                          }}
                        >
                          <Tooltip title={item?.produto?.descricao}>
                            <CardHeader
                              sx={{
                                textAlign: "center",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              title={item?.produto?.descricao}
                            />
                          </Tooltip>
                          <CardContent
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "1fr",
                              gridTemplateRows: "1fr",
                              placeItems: "center",
                              height: "100%",
                            }}
                          >
                            <CircularProgress
                              variant="determinate"
                              value={calculateProgress(item.produto.descricao, item.minQuantity, item.maxQuantity)}
                              sx={{
                                gridArea: "1 / 1 ",
                                width: "160px !important",
                                height: "auto !important",
                                opacity: 1,
                                rotate: "-90deg",
                              }}
                            />
                            <p
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                color: "#344767",
                                fontWeight: "bold",
                              }}
                            >
                              {data?.find((i) => i.produto === item.produto.descricao)?.qtdRecebida || 0}
                            </p>

                            <p
                              style={{
                                position: "absolute",
                                bottom: "0",
                                left: "20%",
                                color: "#344767",
                                fontWeight: "bold",
                              }}
                            >
                              {item.minQuantity}
                            </p>
                            <p
                              style={{
                                position: "absolute",
                                bottom: "0",
                                right: "20%",
                                color: "#344767",
                                fontWeight: "bold",
                              }}
                            >
                              {item.maxQuantity}
                            </p>

                            <CircularProgress
                              variant="determinate"
                              value={50}
                              sx={{
                                gridArea: "1 / 1 ",
                                width: "160px !important",
                                height: "auto !important",
                                opacity: 0.5,
                                rotate: "-90deg",
                              }}
                            />
                          </CardContent>
                        </Card>
                      ))}
                    </Stack>
                  </Stack>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "8px",
                      margin: "1rem",
                      width: "auto",
                      height: "auto",
                      backgroundColor: "#edd7d7",
                      overflowY: "auto",
                    }}
                  >
                    <CardHeader title="MATERIAIS EM ESTOQUE" />
                    <CardContent>
                      {
                        <Stack
                          sx={{
                            display: "flex",
                          }}
                        >
                          {
                            <Stack
                              sx={{
                                display: "flex",
                              }}
                            >
                              {data?.map((item) => (
                                <Stack key={item.produto} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                  <MDTypography variant="body2">{item.produto}</MDTypography>
                                  <Box sx={{ flexGrow: 1, borderBottom: "1px solid", mx: 1 }} />
                                  <MDTypography
                                    variant="body2"
                                    sx={{
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {item.qtdRecebida + " " + item.unidadeMedida}
                                  </MDTypography>
                                </Stack>
                              ))}
                            </Stack>
                          }
                        </Stack>
                      }
                    </CardContent>
                  </Card>
                </Stack>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
