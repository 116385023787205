import { Icon, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import Service from "services/stock";

export default function data(fromParent = false, selectParent = null, setShowAddProduto = null, setLoading = () => {}, filterByWarehouse = null) {
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [sortStr, setSortStr] = useState(null);
  const [filterStr, setFilterStr] = useState(null);

  const fetchData = async () => {
    setLoading(true);

    const { data, limit, total } = await Service.listByWarehouse(filterByWarehouse);
    setPerPage(50);
    setTotalRows(total);

    setRows(
      data.map((row) =>
        Object({
          id: row._id,
          codigoInterno: row.codigoInterno,
          descricao: row.produto,
          productGroup: "ALMOXARIFADO",
          action: row,
        })
      )
    );

    setLoading(false);
  };

  const action = (props) => {
    const row = props?.row?.action;

    return (
      <MDBox display="flex" gap={1}>
        {fromParent && (
          <MDButton
            variant="gradient"
            color="primary"
            fullWidth
            type="submit"
            onClick={() => {
              selectParent(row);
              setShowAddProduto(false);
            }}
            iconOnly
          >
            <Icon fontSize="small">check</Icon>
          </MDButton>
        )}
      </MDBox>
    );
  };

  const updatePagination = ({ page: newPage, pageSize: newPerPage }) => {
    setPage(newPage);
    setPerPage(newPerPage);
  };

  const sortBy = (sortArr) => {
    if (sortArr.length === 0) {
      setSortStr(null);
      return;
    }

    const [filter] = sortArr;

    setSortStr(`&sortBy=${filter.field}&sortOrder=${filter.sort === "asc" ? 1 : "-1"}`);
  };

  const filterBy = ({ items }) => {
    if (items.length === 0) {
      setFilterStr(null);
      return;
    }

    const [filter] = items;

    if (filter.operator === "contains" && filter.value === undefined) {
      return;
    }

    setFilterStr(`&filterField=${filter.field}&filterOperator=${filter.operator}&filterValue=${filter.value}`);
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, sortStr, filterStr]);

  return {
    columns: [
      { headerName: "Código Interno", field: "codigoInterno", flex: 1 },
      { headerName: "Descrição", field: "descricao", flex: 2 },
      { headerName: "Grupo de Produto", field: "productGroup", flex: 1 },
      { headerName: "Ações", field: "action", flex: 1, renderCell: action },
    ],

    rows,
    fetchData,
    updatePagination,
    sortBy,
    filterBy,
    pageObject: {
      totalRows,
      perPage,
      page,
    },
  };
}
