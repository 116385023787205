import * as yup from "yup";

export const yupSchema = yup.object().shape({
  responsavel: yup.string(),
  status: yup.string(),
  dataRetirada: yup.date(),
  warehouseId: yup.string(),
  criadoPor: yup.string(),
  produtos: yup.array(),
});

// .required("Esse campo é obrigatório")

export const initialValues = {
  responsavel: "",
  status: "",
  dataRetirada: "",
  warehouseId: "",
  criadoPor: "",
  produtos: [],
};
